<template>
    <el-dialog v-alterELDialogMarginTop="{marginTop:'7vh'}" :append-to-body="true" title="新建用户" :visible.sync="addUserDialogVisible" 
    :close-on-click-modal="false" width="40%" @close="closeDialog">
        <el-steps class="steps_div" align-center :active="activeIndex" finish-status="success">
            <el-step title="个人信息"></el-step>
            <el-step title="紧急联系人"></el-step>
            <el-step title="其他"></el-step>
            <el-step title="账号"></el-step>
        </el-steps>
        <div class="height_div">
            <el-form :hidden="activeIndex != 0"  ref="userInfoFormRef" label-width="100px" :model="userInfoForm" :rules="userInfoFormRules" v-loading="loading">
                <el-form-item label="姓名" prop="Name">
                    <el-input class="form_input_div" placeholder="请输入姓名" v-model="userInfoForm.Name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="性别" prop="Gender">
                    <el-radio-group class="form_display_flex_div" v-model="userInfoForm.Gender">
                        <el-radio label="男"></el-radio>
                        <el-radio label="女"></el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="城市">
                    <el-col :span="6">
                        <el-cascader class="form_cascader_div" :options="options" v-model="selectedOptions" @change="selectedCityHandleChange"></el-cascader>
                    </el-col>
                </el-form-item>
                <el-form-item label="地址" prop="Address">
                    <el-input class="form_input_div" placeholder="请输入详细地址" v-model="userInfoForm.Address" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="座机" prop="Landline">
                    <el-input class="form_input_div" placeholder="请输入固定电话" v-model="userInfoForm.Landline" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="手机" prop="Phone">
                    <el-input class="form_input_div" placeholder="请输入移动电话" v-model="userInfoForm.Phone" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="身份证" prop="NumberCard">
                    <el-input class="form_input_div" placeholder="请输入身份证号码" v-model="userInfoForm.NumberCard" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="Email">
                    <el-input class="form_input_div" placeholder="请输入邮箱" v-model="userInfoForm.Email" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="微信号" prop="WeChatID">
                    <el-input class="form_input_div" placeholder="请输入微信号码" v-model="userInfoForm.WeChatID" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="生日" prop="Birthday">
                    <el-date-picker class="form_display_flex_div" type="date" 
                    placeholder="选择日期"  
                    format="yyyy 年 MM 月 dd 日" 
                    value-format="yyyy-MM-dd" 
                    v-model="userInfoForm.Birthday"></el-date-picker>
                </el-form-item>
            </el-form>
            <el-form :hidden="activeIndex != 1"  ref="emergencyFormRef" label-width="100px" :model="emergencyForm" :rules="emergencyFormRules" v-loading="loading">
                <el-form-item label="姓名" prop="EmergencyContact">
                    <el-input class="form_input_div" placeholder="请输入紧急联系人姓名" v-model="emergencyForm.EmergencyContact" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="EmergencyPhone">
                    <el-input class="form_input_div" placeholder="请输入紧急联系人电话" v-model="emergencyForm.EmergencyPhone" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <el-form :hidden="activeIndex != 2"  ref="elseInfoFormRef" label-width="100px" :model="elseInfoForm" :rules="elseInfoFormRules" v-loading="loading">
                <el-form-item label="角色" prop="CharacterID">
                    <el-select class="select_div" v-model="elseInfoForm.Character" placeholder="请选择角色" @change="changedCharacter">
                        <el-option v-for="item in characterList" :key="item.id" :label="item.name" :value="{value:item.id,label:item.name}">
                            <span class="span_left_div">{{ item.name }}</span>
                            <span class="span_right_div">{{ item.description }}</span>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属学校" prop="SchoolID">
                    <el-select class="select_div" v-model="elseInfoForm.School" placeholder="请选择学校" @change="changedSchool">
                        <el-option v-for="item in schoolList" :key="item.id" :label="item.name" :value="{value:item.id,label:item.name}">
                            <span class="span_left_div">{{ item.name }}</span>
                            <span class="span_right_div">{{ item.address }}</span>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="职务" prop="IdentityID">
                    <el-select class="select_div" v-model="elseInfoForm.Identity" placeholder="请选择职务" @change="changedIdentify">
                        <el-option v-for="item in identityList" :key="item.id" :label="item.title" :value="{value:item.id,label:item.title}">
                            <span class="span_left_div">{{ item.title }}</span>
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-form :hidden="activeIndex != 3" ref="accountFormRef" label-width="100px" :model="accountForm" :rules="accountFormRules" v-loading="loading">
                <el-form-item label="账号" prop="AccountNumber">
                    <el-input class="form_input_div" v-model="accountForm.AccountNumber" placeholder="请输入账号,用于以后登陆系统(6~11位字符)" autocomplete="off" 
                    oninput="if(value.length>11)value=value.slice(0,11)"
                    onkeyup="this.value=this.value.replace(/[^\w]/g,'')"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="Password">
                    <el-input class="form_input_div" v-model="accountForm.Password" placeholder="请输入密码(7~18位字符)" show-password
                    oninput="if(value.length>18)value=value.slice(0,18)"
                    onkeyup="this.value=this.value.replace(/[^\w]/g,'')"></el-input>
                    <!-- <el-progress class="form_input_div" :percentage="passwordPercent" :format="passwordPercentFormat" :show-text="true" :text-inside="true" :stroke-width="15" :color="pwdColorF">
                    </el-progress> -->
                </el-form-item>
                <el-form-item label="确认密码" prop="MakeSurePassword">
                    <el-input class="form_input_div" v-model="accountForm.MakeSurePassword" placeholder="请确认密码(7~18位字符)" show-password
                    oninput="if(value.length>18)value=value.slice(0,18)"
                    onkeyup="this.value=this.value.replace(/[^\w]/g,'')"></el-input>
                    <!-- <el-progress class="form_input_div" :percentage="passwordPercent" :format="passwordPercentFormat" :show-text="true" :text-inside="true" :stroke-width="15" :color="pwdColorF">
                    </el-progress> -->
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button v-if="activeIndex != 0" @click="previousStep" :disabled="loading">上一步</el-button>
            <el-button v-if="activeIndex != 3" @click="nextStep" :disabled="loading">下一步</el-button>
            <el-button v-if="activeIndex == 3" @click="makeSureInfo" type="primary" :disabled="loading">确认</el-button>
        </span>
    </el-dialog>    
</template> 
<script>
// 作用是移除校验结果并重置字段值
// this.$refs.form.resetFields();
// 如果只是想移除校验结果，使用方法如下：
// this.$refs.form.clearValidate();
import { GetCharacterList } from '@/assets/js/api/characterapi'
import { GetSchools } from '@/assets/js/api/schoolapi'
import { GetIdentityList } from '@/assets/js/api/Identityapi'
import { PostAccountUser } from '@/assets/js/api/userapi'
import { validateIdent  } from '@/assets/js/util/card'
import { validateEmail, validateMobile  } from '@/assets/js/util/common'
import { GetGUID  } from '@/assets/js/common'
import { provinceAndCityData, CodeToText,TextToCode } from 'element-china-area-data'
export default {
    props:{
        visible:{
            type: Boolean,
            default:false
        },
    },
    computed:{
        addUserDialogVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        }
    },
    data(){
        var checkEmail = (rule,value,callback) => { 
            if(validateEmail(value)){
                return callback()
            }
            callback(new Error('请输入合法邮箱'))
        }
        var checkMobilePhone=(rule,value,callback)=>{
            if(validateMobile(value)){
                return callback()
            }
            callback(new Error('请输入合法手机号码'))
        }
        var checkCard=(rule,value,callback)=>{
            let data = value.toUpperCase()
            if(validateIdent.IdentityCodeValid(data)){
                return callback()
            }
            callback(new Error('请输入合法身份证号码'))
        }
        var checkAccountLength=(rule,value,callback)=>{
            if(value.length >= 6){
                return callback()
            }
            callback(new Error('账号长度最小6位字符'))
        }
        var checkPassword=(rule,value,callback)=>{
            if(value.length >= 7){
                return callback()
            }
            callback(new Error('密码长度最小7位字符'))
        }
        return{
            passwordPercent:0,
            activeIndex:0,
            accountForm:{
                AccountNumber:'',   //账号*
                Password:'',        //密码*
                MakeSurePassword:'',//确认密码
                Key:'',             //用于加密密码的key
            },
            accountFormRules:{
                AccountNumber: [
                    { required: true, message: '请输入账号，用于以后登陆系统', trigger: 'blur' },
                    { validator:checkAccountLength, trigger: 'blur'}
                ],
                Password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { validator:checkPassword, trigger: 'blur'}
                ],
                MakeSurePassword: [
                    { required: true, message: '请输入确认密码', trigger: 'blur' },
                    { validator:checkPassword, trigger: 'blur'}
                ],
            },
            userInfoForm:{
                Name:'',                //姓名
                Gender:'男',            //性别
                Province:'山东省',      //省份
                City:'潍坊市',          //城市
                Address:'',             //地址
                Landline:'',            //座机
                Phone:'',               //手机
                Email:'',               //邮箱
                NumberCard:'',          //身份证
                Birthday:new Date().toLocaleDateString().split('/').map(item=>{if (item<10){return '0'+ item}else {return item}}).join('-'),    //出生日期
            },
            userInfoFormRules:{
                Name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' }
                ],
                Gender: [
                    { required: true, message: '请选择性别', trigger: 'change' }
                ],
                Address: [
                    { required: true, message: '请输入地址', trigger: 'blur' }
                ],
                Phone: [
                    { required: true, message: '请输入手机', trigger: 'blur' },
                    { validator:checkMobilePhone, trigger: 'blur'}
                ],
                Email: [
                    { required: true, message: '请输入邮箱', trigger: 'blur' },
                    { validator:checkEmail, trigger: 'blur'}
                ],
                NumberCard: [
                    { required: true, message: '请输入身份证', trigger: 'blur' },
                    { validator:checkCard, trigger: 'blur'}
                ],
            },
            emergencyForm:{
                EmergencyContact:'',    //紧急联系人
                EmergencyPhone:'',      //紧急联系人电话
            },
            emergencyFormRules:{
                EmergencyContact: [
                    { required: false, message: '请输入姓名', trigger: 'blur' }
                ],
                EmergencyPhone: [
                    { required: false, message: '请选择电话', trigger: 'change' }
                ],
            },
            elseInfoForm:{
                Character:'',
                CharacterID:'',  //角色ID
                School:'',
                SchoolID:'',     //所属学校
                Identity:'',
                IdentityID:'',   //职务
            },
            elseInfoFormRules:{
                CharacterID: [
                    { required: true, message: '请选择角色', trigger: 'change' }
                ],
                SchoolID: [
                    { required: true, message: '请选择学校', trigger: 'change' }
                ],
                IdentityID: [
                    { required: true, message: '请学则职务', trigger: 'change' }
                ]
            },
            options: provinceAndCityData,
            selectedOptions: [TextToCode["山东省"].code.toString(),TextToCode["山东省"]["潍坊市"].code.toString()],
            characterList:[],
            schoolList:[],
            identityList:[],
            loading:false,
            idLoading:true,
            scLoading:true,
            chLoading:true,
        }
    },
    watch:{
        addUserDialogVisible:function (newVisible) {
            if(newVisible){
                this.activeIndex = 0
                if(this.$refs.accountFormRef)
                {
                    this.$refs.accountFormRef.resetFields()
                }
                if(this.$refs.userInfoFormRef)
                {
                    this.$refs.userInfoFormRef.resetFields()
                }
                if(this.$refs.emergencyFormRef)
                {
                    this.$refs.emergencyFormRef.resetFields()
                }
                if(this.$refs.elseInfoFormRef)
                {
                    this.$refs.elseInfoFormRef.resetFields()
                }
                this.getCharacters()
                this.getSchools()
                this.getIdentities()
            }
        },
        idLoading: function(newval){
            if(newval == false && this.scLoading == false && this.chLoading == false){
                this.loading = false
            }
        },
        scLoading: function(newval){
            if(newval == false && this.idLoading == false && this.chLoading == false){
                this.loading = false
            }
        },
        chLoading: function(newval){
            if(this.scLoading == false && this.idLoading == false && newval == false){
                this.loading = false
            }
        },
    },
    methods:{
        async getCharacters(){
            this.chLoading = true
            var ret = await GetCharacterList()
            this.chLoading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.characterList = ret.data;
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        async getSchools(){
            this.scLoading = true
            var ret = await GetSchools()
            this.scLoading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.schoolList = ret.data;
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        async getIdentities(){
            this.idLoading = true
            var ret = await GetIdentityList()
            this.idLoading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.identityList = ret.data;
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        async closeDialog(){
            if(this.$refs.accountFormRef)
            {
                this.$refs.accountFormRef.resetFields()
            }
            if(this.$refs.userInfoFormRef)
            {
                this.$refs.userInfoFormRef.resetFields()
            }
            if(this.$refs.emergencyFormRef)
            {
                this.$refs.emergencyFormRef.resetFields()
            }
            if(this.$refs.elseInfoFormRef)
            {
                this.$refs.elseInfoFormRef.resetFields()
            }
            this.addUserDialogVisible = false
        },
        nextStep(){
            if(this.activeIndex == 0){
                this.$refs.userInfoFormRef.validate(valid =>{
                    this.validret = valid
                })
                if(!this.validret){
                    return
                }
                this.activeIndex++
            }
            else if(this.activeIndex == 1){
                this.activeIndex++
            }
            else if(this.activeIndex == 2){
                this.$refs.elseInfoFormRef.validate(valid =>{
                    this.validret = valid
                })
                if(!this.validret){
                    return
                }
                this.activeIndex++
            }
        },
        previousStep(){
            if(this.activeIndex == 3){
                if(this.$refs.accountFormRef)
                {
                    this.$refs.accountFormRef.clearValidate()
                }
            }
            else if(this.activeIndex == 2)
            {
                if(this.$refs.elseInfoFormRef)
                {
                    this.$refs.elseInfoFormRef.clearValidate()
                }
            }
            this.activeIndex --
        },
        selectedCityHandleChange(value) {
            this.userInfoForm.Province = CodeToText[value[0]]
            this.userInfoForm.City = CodeToText[value[1]]
        },
        changedCharacter(val){
            this.elseInfoForm.CharacterID = val.value
            this.elseInfoForm.Character = val.label
        },
        changedSchool(val){
            this.elseInfoForm.SchoolID = val.value
            this.elseInfoForm.School = val.label
        },
        changedIdentify(val){
            this.elseInfoForm.IdentityID = val.value
            this.elseInfoForm.Identity = val.label
        },
        async makeSureInfo(){
            this.$refs.accountFormRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            }
            if(this.accountForm.Password != this.accountForm.MakeSurePassword){
                return this.$message.warning('两次密码不同,请重新确认密码');
            }
            const confirmResult = await this.$confirm('即将新建新的用户信息, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return this.$message.info('已取消操作')
            }
            this.accountForm.Key = GetGUID();
            var obj = {}
            var arr1 = Object.keys(this.userInfoForm)
            for(var i =0; i< arr1.length; i++)
            {
                obj[arr1[i]] = this.userInfoForm[arr1[i]]
            }
            var arr2 = Object.keys(this.accountForm)
            for(var j =0; j< arr2.length; j++)
            {
                obj[arr2[j]] = this.accountForm[arr2[j]]
            }
            var arr3 = Object.keys(this.emergencyForm)
            for(var k =0; k< arr3.length; k++)
            {
                obj[arr3[k]] = this.emergencyForm[arr3[k]]
            }
            var arr4 = Object.keys(this.elseInfoForm)
            for(var l =0; l< arr4.length; l++)
            {
                obj[arr4[l]] = this.elseInfoForm[arr4[l]]
            }
            let sha512 = require("js-sha512").sha512
            obj.Password = sha512(this.accountForm.Password  + this.accountForm.Key)
            obj.MakeSurePassword =  obj.Password
            obj.Employee = true //员工标志
            this.loading = true
            var ret = await PostAccountUser(obj)
            this.loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('添加用户成功')
                this.$emit("ChangeAddUserInfo")
                this.closeDialog()
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        passwordPercentFormat(percentage) {
            return percentage == 30 ? '弱' : percentage == 60 ? '中' : percentage == 100 ? '强' : '弱'
        },
        pwdColorF(percentage) {
            return percentage == 30 ? '#FF5340' : percentage == 60 ? '#FFB640' : percentage == 100 ? '#25DC1B' : '#FF5340'
        },
    }
}
</script>
<style lang="less" scoped>
@import url("@/assets/less/projectless/dialog/user/adduser.less");
</style>