import axios from 'axios';

//获取身份列表
export async function GetIdentityList(){
    var res = await axios.get('Identity/v1/Identities')
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
}