<template>
    <div class="list-div">
        <el-card class="el-card-mainview">
            <el-tabs type="card" @tab-click="tabsClick">
                <el-tab-pane label="在职员工">
                    <el-card>
                        <el-row :gutter="30">
                            <el-col :span="8">
                                <el-input placeholder="请输入用户名" v-model="queryInfo.name" clearable @clear="queryUserList"> 
                                    <el-button slot="append" icon="el-icon-search" @click="queryUserList"></el-button>
                                </el-input>
                            </el-col>
                            <el-col :span="4">
                                <el-button type="primary" @click="showAddUserDialog">新建用户</el-button> 
                            </el-col>
                        </el-row>
                        <el-table :data="userlist" @row-click="showmodifyUserInfo" v-loading="loading" height="65vh">
                            <el-table-column label="#" type="index"></el-table-column>
                            <el-table-column label="姓名" prop="name"></el-table-column>
                            <el-table-column label="账号" prop="accountNumber"></el-table-column>
                            <el-table-column label="性别" prop="gender"></el-table-column>
                            <el-table-column label="邮箱" prop="email" :width="flexColumnWidth('邮箱', 'email')"></el-table-column>
                            <el-table-column label="电话" prop="phone" :width="flexColumnWidth('电话', 'phone')"></el-table-column>
                            <el-table-column label="省份" prop="province"></el-table-column>
                            <el-table-column label="城市" prop="city"></el-table-column>
                            <el-table-column label="地址" prop="address" :width="flexColumnWidth('地址', 'address')"></el-table-column>
                        </el-table>
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="queryInfo.start"
                            :page-sizes="spreadCount"
                            :page-size="queryInfo.count"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="userstotal">
                        </el-pagination>
                    </el-card>
                </el-tab-pane>
                <el-tab-pane label="离职员工">
                    <el-card>
                        <el-row :gutter="30">
                            <el-col :span="8">
                                <el-input placeholder="请输入用户名" v-model="queryInfo.name" clearable @clear="queryUserList"> 
                                    <el-button slot="append" icon="el-icon-search" @click="queryUserList"></el-button>
                                </el-input>
                            </el-col>
                        </el-row>
                        <el-table :data="userlist" stripe v-loading="loading" height="65vh">
                            <el-table-column label="#" type="index"></el-table-column>
                            <el-table-column label="姓名" prop="name"></el-table-column>
                            <el-table-column label="账号" prop="accountNumber"></el-table-column>
                            <el-table-column label="性别" prop="gender"></el-table-column>
                            <el-table-column label="邮箱" prop="email" :width="flexColumnWidth('邮箱', 'email')"></el-table-column>
                            <el-table-column label="电话" prop="phone" :width="flexColumnWidth('电话', 'phone')"></el-table-column>
                            <el-table-column label="省份" prop="province"></el-table-column>
                            <el-table-column label="城市" prop="city"></el-table-column>
                            <el-table-column label="地址" prop="address" :width="flexColumnWidth('地址', 'address')"></el-table-column>
                            <el-table-column label="操作" prop="option" width="300">
                                <template slot-scope="scope"> 
                                    <el-tooltip effect="dark" content="恢复用户" placement="top" :enterable="false">
                                        <el-button type="success" icon="el-icon-check" @click="modifyUserInfoByUserID(scope.row.id)"></el-button>
                                    </el-tooltip>
                                    <el-tooltip effect="dark" content="删除用户" placement="top" :enterable="false">
                                        <el-button type="danger" icon="el-icon-delete" @click="deleteUserbyID(scope.row.id)"></el-button>
                                    </el-tooltip>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="queryInfo.start"
                            :page-sizes="spreadCount"
                            :page-size="queryInfo.count"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="userstotal">
                        </el-pagination>
                    </el-card>
                </el-tab-pane>
            </el-tabs>
        </el-card>
        <AddUserDialog :visible.sync="AddUserDialogVisible"
         v-on:ChangeAddUserInfo="queryUserList">
        </AddUserDialog>

        <UserSettingDialog :visible.sync="ModifyUserDialogVisible" :userdata="ModifyUserData"
        v-on:ChangeModifyUserInfo="queryUserList">
        </UserSettingDialog>
    </div>
</template>

<script>
import AddUserDialog from './Dialog/adduserdialog.vue';
import UserSettingDialog from './Dialog/usersettingdialog.vue';
import { GetMaxLength } from '@/assets/js/common.js';
import { GetUsersList, PutModifyUserState, DeleteUserAccount } from '@/assets/js/api/userapi';
import { SpreadCount } from '@/assets/js/systemvariable.js';
export default{
    components:{
        AddUserDialog,
        UserSettingDialog,
    },
    data(){
        return{
            spreadCount:SpreadCount,
            userlist:[],
            userstotal:0,
            queryInfo:{
                name:'',
                employeetag:1,
                state:0,
                start:1,
                count:SpreadCount[0]
            },
            modifyuserstate:{
                state:0,
                userID:0
            },
            loading:false,
            AddUserDialogVisible:false,
            ModifyUserDialogVisible:false,
            ModifyUserData:{},
        }
    },
    created(){
        this.modifyuserstate.state = 1
        this.queryUserList()
    },
    methods:{
        queryUserList(){
            this.queryInfo.start = 1
            this.queryInfo.count = this.spreadCount[0]
            this.getUserList()
        },
        async getUserList(){
            this.loading = true
            var ret = await GetUsersList(this.queryInfo)
            this.loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.userlist = ret.data.users
                this.userstotal = ret.data.total
            }
            else
            {
                this.$message.error(ret.message);
            } 
        },
        handleSizeChange(count){
            this.queryInfo.count = count
            this.getUserList()
        },
        handleCurrentChange(start){
            this.queryInfo.start = start
            this.getUserList()
        },
        async modifyUserInfoByUserID(userID){
            const confirmResult = await this.$confirm('此操作将改变该用户的身份状态, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return this.$message.info('已取消操作')
            }
            this.modifyuserstate.userID = userID 
            this.loading = true
            var ret = await PutModifyUserState(this.modifyuserstate)
            this.loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('设置成功')
                this.queryUserList()
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        tabsClick(tab){
            if(tab.label == '在职员工'){
                this.queryInfo.state = 0
                this.modifyuserstate.state = 1
            }
            else if(tab.label == '离职员工'){
                this.queryInfo.state = 1
                this.modifyuserstate.state= 0
            }
            this.queryUserList()
        },
        async deleteUserbyID(userID){
            const confirmResult = await this.$confirm('此操作将永远删除该用户, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return this.$message.info('已取消删除')
            }
            this.loading = true
            var ret = await DeleteUserAccount(userID)
            this.loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('删除成功')
                this.queryUserList()
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        showAddUserDialog(){
            this.AddUserDialogVisible = true
            //this.$router.push('/AddUser')
            // var employee = true
            // this.$router.push({
            //     name:'AddUser',
            //     query:{
            //         EmployeeTag: JSON.stringify(true)
            //     }
            // })
        },
        showmodifyUserInfo(userinfo){
            this.ModifyUserData = userinfo
            this.ModifyUserDialogVisible = true
        },
        flexColumnWidth(label, prop){
            const arr = this.userlist.map(x => x[prop])
            arr.push(label) 
            return (GetMaxLength(arr) + 25) + 'px'
        },
    }
}
</script>